import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <container>
        <row>
          <div>
            <h2>Welcome!! This is the home of Jagans domains</h2>
          </div>
          <div>
            Following are the list of domains available for sale. Email me at{" "}
            <img src="JagansEmail.png" border="0"></img> with your offer if you
            are interested in a domain.
          </div>
          <div>
            <table border="1" align="center">
              <tr>
                <td>Albany2030.com</td>
              </tr>
              <tr>
                <td>ALIndia.com</td>
              </tr>
              <tr>
                <td>AQC.org</td>
              </tr>
              <tr>
                <td>Asrad.com</td>
              </tr>
              <tr>
                <td>Astuti.com</td>
              </tr>
              <tr>
                <td>Banaj.com</td>
              </tr>
              <tr>
                <td>BCQI.com</td>
              </tr>
              <tr>
                <td>Blondy.org</td>
              </tr>
              <tr>
                <td>Bosanka.com</td>
              </tr>
              <tr>
                <td>BrailleTranslator.com</td>
              </tr>
              <tr>
                <td>BrandyBay.com</td>
              </tr>
              <tr>
                <td>BreakfastBed.com</td>
              </tr>
              <tr>
                <td>Chairolution.com</td>
              </tr>
              <tr>
                <td>Cheldan.com</td>
              </tr>
              <tr>
                <td>CinemaWatch.com</td>
              </tr>
              <tr>
                <td>CondoRio.com</td>
              </tr>
              <tr>
                <td>CreditBill.com</td>
              </tr>
              <tr>
                <td>CTIndia.com</td>
              </tr>
              <tr>
                <td>Dilveer.com</td>
              </tr>
              <tr>
                <td>Dilvir.com</td>
              </tr>
              <tr>
                <td>Dost.me</td>
              </tr>
              <tr>
                <td>DownComforter.net</td>
              </tr>
              <tr>
                <td>DryCircle.com</td>
              </tr>
              <tr>
                <td>DryRing.com</td>
              </tr>
              <tr>
                <td>Ecimer.com</td>
              </tr>
              <tr>
                <td>EFGK.com</td>
              </tr>
              <tr>
                <td>EmpireStatePlaza.com</td>
              </tr>
              <tr>
                <td>EvictionNow.com</td>
              </tr>
              <tr>
                <td>FarmlandBrokers.com</td>
              </tr>
              <tr>
                <td>FishOnTheGrill.com</td>
              </tr>
              <tr>
                <td>FlexibleStorage.com</td>
              </tr>
              <tr>
                <td>Genichesk.com</td>
              </tr>
              <tr>
                <td>GrandVenturesLLC.com</td>
              </tr>
              <tr>
                <td>GunChest.com</td>
              </tr>
              <tr>
                <td>Gym.sc</td>
              </tr>
              <tr>
                <td>Haemek.com</td>
              </tr>
              <tr>
                <td>Henichesk.com</td>
              </tr>
              <tr>
                <td>HotelBrokers.net</td>
              </tr>
              <tr>
                <td>HXNO.com</td>
              </tr>
              <tr>
                <td>IAIndia.com</td>
              </tr>
              <tr>
                <td>IamLast.co</td>
              </tr>
              <tr>
                <td>IamLost.co</td>
              </tr>
              <tr>
                <td>IamLost.net</td>
              </tr>
              <tr>
                <td>IDIndia.com</td>
              </tr>
              <tr>
                <td>InfiniteRecharge.com</td>
              </tr>
              <tr>
                <td>Iyappa.com</td>
              </tr>
              <tr>
                <td>Iyappa.net</td>
              </tr>
              <tr>
                <td>Iyappa.org</td>
              </tr>
              <tr>
                <td>Jagan.com</td>
              </tr>
              <tr>
                <td>Jagan.net</td>
              </tr>
              <tr>
                <td>Jagans.com</td>
              </tr>
              <tr>
                <td>JuiceCups.com</td>
              </tr>
              <tr>
                <td>Kalpana.org</td>
              </tr>
              <tr>
                <td>KingdomWord.com</td>
              </tr>
              <tr>
                <td>KJIP.com</td>
              </tr>
              <tr>
                <td>KNHA.com</td>
              </tr>
              <tr>
                <td>KTOJ.com</td>
              </tr>
              <tr>
                <td>Kulambu.com</td>
              </tr>
              <tr>
                <td>Kumphawapi.com</td>
              </tr>
              <tr>
                <td>Lagna.co</td>
              </tr>
              <tr>
                <td>Lagnam.co</td>
              </tr>
              <tr>
                <td>LakeGeorgeDental.com</td>
              </tr>
              <tr>
                <td>LeanMetrics.com</td>
              </tr>
              <tr>
                <td>Lenurple.com</td>
              </tr>
              <tr>
                <td>Lin.pe</td>
              </tr>
              <tr>
                <td>Logic.us</td>
              </tr>
              <tr>
                <td>Lugnam.com</td>
              </tr>
              <tr>
                <td>Lugnum.com</td>
              </tr>
              <tr>
                <td>MedicalAgro.com</td>
              </tr>
              <tr>
                <td>Mitchal.com</td>
              </tr>
              <tr>
                <td>MNIndia.com</td>
              </tr>
              <tr>
                <td>MobileCardMachine.com</td>
              </tr>
              <tr>
                <td>MobileCardPaymentMachine.com</td>
              </tr>
              <tr>
                <td>MrIron.com</td>
              </tr>
              <tr>
                <td>MSXV.com</td>
              </tr>
              <tr>
                <td>Nablusi.com</td>
              </tr>
              <tr>
                <td>NavyRetired.com</td>
              </tr>
              <tr>
                <td>NDIndia.com</td>
              </tr>
              <tr>
                <td>NHKL.com</td>
              </tr>
              <tr>
                <td>NoorinTV.com</td>
              </tr>
              <tr>
                <td>NVIndia.com</td>
              </tr>
              <tr>
                <td>NYAlbanyRentals.com</td>
              </tr>
              <tr>
                <td>NYHasItAll.com</td>
              </tr>
              <tr>
                <td>OBYG.com</td>
              </tr>
              <tr>
                <td>Odiya.TV</td>
              </tr>
              <tr>
                <td>OHIndia.com</td>
              </tr>
              <tr>
                <td>Oriya.TV</td>
              </tr>
              <tr>
                <td>OYBD.com</td>
              </tr>
              <tr>
                <td>PAIndia.com</td>
              </tr>
              <tr>
                <td>PinkApparel.com</td>
              </tr>
              <tr>
                <td>PinkApparels.com</td>
              </tr>
              <tr>
                <td>Pranav.co</td>
              </tr>
              <tr>
                <td>Pranav.us</td>
              </tr>
              <tr>
                <td>Pranics.com</td>
              </tr>
              <tr>
                <td>PranicTea.com</td>
              </tr>
              <tr>
                <td>PrintersAllied.com</td>
              </tr>
              <tr>
                <td>PuttingIron.com</td>
              </tr>
              <tr>
                <td>QAKZ.com</td>
              </tr>
              <tr>
                <td>QuadroVentures.com</td>
              </tr>
              <tr>
                <td>QuickFit.net</td>
              </tr>
              <tr>
                <td>Raccolta.com</td>
              </tr>
              <tr>
                <td>Rascette.com</td>
              </tr>
              <tr>
                <td>RDZI.com</td>
              </tr>
              <tr>
                <td>RentalPerson.com</td>
              </tr>
              <tr>
                <td>ResumeForward.com</td>
              </tr>
              <tr>
                <td>RIIndia.com</td>
              </tr>
              <tr>
                <td>RJBI.com</td>
              </tr>
              <tr>
                <td>Rotna.com</td>
              </tr>
              <tr>
                <td>Ru.pe</td>
              </tr>
              <tr>
                <td>Sakthi.co</td>
              </tr>
              <tr>
                <td>Sasila.com</td>
              </tr>
              <tr>
                <td>Schraibman.com</td>
              </tr>
              <tr>
                <td>ScrumPlus.com</td>
              </tr>
              <tr>
                <td>SecludedVillas.com</td>
              </tr>
              <tr>
                <td>Sinhalaya.TV</td>
              </tr>
              <tr>
                <td>Sinhalese.TV</td>
              </tr>
              <tr>
                <td>Slavian.com</td>
              </tr>
              <tr>
                <td>SOFQ.com</td>
              </tr>
              <tr>
                <td>Solawi.com</td>
              </tr>
              <tr>
                <td>Spansoft.com</td>
              </tr>
              <tr>
                <td>Spansoft.net</td>
              </tr>
              <tr>
                <td>SpookyNights.com</td>
              </tr>
              <tr>
                <td>Stahlbrode.com</td>
              </tr>
              <tr>
                <td>SupportPrecisionAgriculture.com</td>
              </tr>
              <tr>
                <td>Svetha.com</td>
              </tr>
              <tr>
                <td>Szigethalom.com</td>
              </tr>
              <tr>
                <td>TacoRaja.com</td>
              </tr>
              <tr>
                <td>Thayir.com</td>
              </tr>
              <tr>
                <td>Transphase.com</td>
              </tr>
              <tr>
                <td>TXIndia.com</td>
              </tr>
              <tr>
                <td>UJKS.com</td>
              </tr>
              <tr>
                <td>Urdu.TV</td>
              </tr>
              <tr>
                <td>UTIndia.com</td>
              </tr>
              <tr>
                <td>VAIndia.com</td>
              </tr>
              <tr>
                <td>VHDB.com</td>
              </tr>
              <tr>
                <td>VillaTV.com</td>
              </tr>
              <tr>
                <td>VJTO.com</td>
              </tr>
              <tr>
                <td>WAIndia.com</td>
              </tr>
              <tr>
                <td>WCWO.com</td>
              </tr>
              <tr>
                <td>WIIndia.com</td>
              </tr>
              <tr>
                <td>WVIndia.com</td>
              </tr>
              <tr>
                <td>WYIndia.com</td>
              </tr>
              <tr>
                <td>🥞.ws Pancake Emoji domain</td>
              </tr>
              <tr>
                <td>Iceland flag Emoji domain xn--m77hua.ws</td>
              </tr>
              <tr>
                <td>Serbia flag Emoji domain xn--v77hc.ws</td>
              </tr>
              <tr>
                <td>🥜.ws Peanut Emoji domain</td>
              </tr>
              <tr>
                <td>Yayalakent.com</td>
              </tr>
              <tr>
                <td>ZDOB.com</td>
              </tr>
              <tr>
                <td>AUbAUr.com</td>
              </tr>
              <tr>
                <td>AUBornAURaised.com</td>
              </tr>
              <tr>
                <td>CABornCARaised.com</td>
              </tr>
              <tr>
                <td>EUbEUr.com</td>
              </tr>
              <tr>
                <td>EUBornEURaised.com</td>
              </tr>
              <tr>
                <td>UKbUKr.com</td>
              </tr>
              <tr>
                <td>UKBornUKRaised.com</td>
              </tr>
              <tr>
                <td>USbUSr.com</td>
              </tr>
              <tr>
                <td>USBornUSRaised.com</td>
              </tr>
              <tr>
                <td>USBornMatrimony.com</td>
              </tr>
            </table>
          </div>
        </row>
      </container>
    </div>
  );
}

export default App;
